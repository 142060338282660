// --- Variables --------------------------------

// Colors
$primary-color: 		#589383;
$primary-hover:			lighten($primary-color, 10%);

// Sizes
$mobile-ends: 			768px;
$tablet-starts: 		769px;
$tablet-ends: 			959px;
$desktop-starts: 		960px;
