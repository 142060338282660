body { overflow-x: hidden; }

a { color: $primary-color; }
a:hover, a:active { color: lighten($primary-color, 20%); }

iframe { max-width: 100%; }

.button {
  height: 100%;
  white-space: unset;
}

.is-full-width {
  width: 100%;
}

.Site {
  padding: 0;
  margin: 1rem;

  @media screen and (min-width: $tablet-starts) {
    padding: 0 20%;
    margin: 3.25rem 0;
  }
}

#navbar {
  padding: 0 1.5rem;

  @media screen and (min-width: $tablet-starts) {
    padding-left: calc(20% - 1rem);
    padding-right: calc(20% - 1rem);
  }
}

.content-container { margin: 4.5rem 0; }
.section-container { margin: 1.5rem 0; }

.copyright { font-size: 12px; }
.attribution {
  text-align: right;
  display: block;
  position: relative;
  top: -15px;
  font-size: 12px;
  color: lightgray;
  transition: all 1s ease-in-out;
  opacity: 0;
  top: calc(100% + 5px);

  &:not(:empty){ opacity: 1; } // cool fade-in effect
}

.parallax-hero {
  height: 600px;

  margin: 0 -34% 1.5rem;
  padding: 0px 34%;
  background-color: transparent;

  .hero-body {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.parallax {
  position: relative;
  padding: 0;

  .parallax-loading-screen {
    position: absolute;
    height: calc(100% - 15px);
    width: 100%;
    top: 15px;
    left: 0;
    z-index: 3;
    background-color: $primary-color;
  }
}
