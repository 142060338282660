// Index
.index {
  margin-bottom: 4.5rem; 

  img {
    border: 1px solid #e1e1e1;
    width: 100%;
  }

  #header {
    margin-bottom: 0;
  
    @media screen and (min-width: $tablet-starts) {
      margin-bottom: 4.5rem;
    }
  }

  .profile-image {
    margin-bottom: 10px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;

      @media screen and (min-width: $tablet-starts) {
        max-height: 300px;
      }
    }
  }

  .resume-image {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border: 1px solid #e1e1e1;
      border-radius: 0;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: .3s;

      @media screen and (min-width: $tablet-starts) {
        width: initial;
        max-width: 400px;
      }

      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
     }
    }
  }

  .tile .content.row-content {
    img, p { display: inline; }
    img {
      float: left;
      margin-right: 20px;
      max-height: 170px;
      object-fit: cover;
    }
  }

  #projects {
    .title .icon {
      color: darken(whitesmoke, 10%);
      margin-right: 5px;
    }
  }
}

.no-top-margin {
  margin-top: 0 !important;
}

footer {
  padding: 3rem 1.5rem !important;
}

// Blog and posts

.post-title { margin-bottom: 10px; }

#post article { margin-bottom: 1.5rem; }

#career {
  padding: 6rem 1.5rem !important;
  font-size: 1rem;
}
